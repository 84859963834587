import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'H5Page',
    component: () => import('@/views/langhuaVideo/H5')
  },
  {
    path: '/h5Page',
    name: 'H5Page',
    component: () => import('@/views/langhuaVideo/H5')
  },
  {
    path: '/downLoad',
    name: 'downLoad',
    component: () => import('@/views/langhuaVideo/downLoad')
  }
]

const router = new VueRouter({
  routes
})

export default router

import { base64Decode } from '@xy/core'
/** 检测当前设备信息*/
export function checkDevice() {
	var u = navigator.userAgent 
  let deviceInfo = {}
  deviceInfo.isAndroid = !!(u.indexOf('Android') > -1 || u.indexOf('Adr') > -1) //android终端
  deviceInfo.isiOS = !!u.match(/(iPhone|iPod|ios|iPad)/i) //ios终端
  deviceInfo.issafariBrowser = /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)
  deviceInfo.isMobile = deviceInfo.isAndroid || deviceInfo.isiOS
  window.deviceInfo = deviceInfo
  return deviceInfo
}
/** 检测当前设备信息*/
export function getChannelCode(params) {
  if(!params){
    return process.env.VUE_APP_CHANNEL_CODE
  }
  const query = base64Decode(params)
  let a = query.split('##')
  let channelCode = a[1]
  let appId = a[3]
  if(appId==process.env.VUE_APP_APP_ID){
    // 如果appid相同 使用参数地址渠道包
    return channelCode
  }else{
    return process.env.VUE_APP_CHANNEL_CODE
  }
}
